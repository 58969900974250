import * as React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Container from './Container';
import backgroundImage from '../../assets/background.jpg';
import logo from '../../assets/logo.png';
import brazilflag from '../../assets/langs/ptbr.png';
import spainflag from '../../assets/langs/es.gif';
import usaflag from '../../assets/langs/en.jpg';
import { widths } from '../styles/variables'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LogoImg = styled.img`
  width: 80% !important;
  height: auto;
`;

const StyledHeader = styled.header`
  margin: 0 auto;
  width: 100%;
  color: #fff;
  margin-top: 10px;
`;

const HeaderInner = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  padding: 0;
  background: transparent;
  width: 100%;
`;

const MenuInner = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: space-between;
  height: 100%;
  padding: 8px;
  // background: transparent;
  width: 100%;
  // background: rgba(0, 0, 0, 0.7);
  background: #fafafa;

`;

const LanguagesInner = styled(Container)`
  display: flex;
  height: 100%;
  width: 100%;
  padding: 0;
  background: transparent;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;
`;

const LanguagesFlagImg = styled.img`
  width: 100%;
  height: 30px;
  width: auto;
  margin: 8px;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  &:hover,
  &:focus {
    border: 1px solid #fff;
  }
`;

const HomepageLink = styled(Link)`
  font-size: 21px;
  margin: 0 16px;
  background: transparent;
  color: #fff;
  color: #1f3447 !important;
  // text-shadow: 1px 1px 2px #000;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const HomepageA = styled.a`
  font-size: 21px;
  margin: 0 16px;
  background: transparent;
  color: #fff;
  color: #1f3447 !important;
  // text-shadow: 1px 1px 2px #000;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const FullSideInner = styled(Container)`
  padding: 8px;
  background: transparent;
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;

  @media (max-width: ${widths.md}px) {
    text-align: center;
  }

  .logoMenu {
    width: 80px;
    height: auto;

    @media (max-width: ${widths.md}px) {
      width: 100%;
      height: auto;
    }
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
`;

const LogoContainer = styled.div`
  width: 30%;
  margin: 0 auto;
  padding: 32px;
  height: 500px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: ${widths.md}px) {
    width: 50%;
    height: auto;
  }

  @media (min-width: ${widths.md}px) and (max-width: ${widths.md + 400}px) {
    width: 50%;
    height: auto;
  }

  @media (min-width: ${widths.md + 700}px) {
    width: 25%;
    height: 500px;
  }
`;

const SliderPage = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    margin: 0 auto;
    height: auto;
    width: 100%;
  }
`;

const Header: React.FC = ({ logoImage, backgroundImg }) => {
  console.log("logoImage: ", logoImage);
  let urlLogoImg = "https://lojasinergia.machadoti.com.br" + logoImage;
  if (!logoImage) urlLogoImg = logo;

  console.log("backgroundImg: ", backgroundImg);
  /*let urlBackgroundImg = "https://lojasinergia.machadoti.com.br" + backgroundImg;
  if (!backgroundImg) urlBackgroundImg = null;*/

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <>
      <MenuInner>
        <FullSideInner>
          <Link to="/"><LogoImg src={urlLogoImg} alt="Logo" className="logoMenu" /></Link>
          <HomepageLink to="/instituicao">Institucional</HomepageLink>
          <HomepageLink to="/noticias">Reuniões e Eventos</HomepageLink>
          <HomepageLink to="/contato">Contato com a Loja</HomepageLink>
          {/* <HomepageLink to="/noticias">Notícias</HomepageLink> */}
          {/* <HomepageA href="mailto:124sec@glojars.org.br">Contato com a loja</HomepageA> */}
        </FullSideInner>
      </MenuInner>
      {backgroundImg && <Slider {...settings}>
        {backgroundImg.map(img => {
          let url = "https://lojasinergia.machadoti.com.br" + img.url;
          return <SliderPage>
            <img src={url} />
          </SliderPage>;
        })}
      </Slider>}
      <StyledHeader>
        <HeaderInner>
          <HeaderContainer>
            <LanguagesInner>
              <LanguagesFlagImg src={brazilflag} alt="Português - Brasil" />
              <LanguagesFlagImg src={usaflag} alt="Engilsh" />
              <LanguagesFlagImg src={spainflag} alt="Español" />
            </LanguagesInner>
          </HeaderContainer>
        </HeaderInner>
      </StyledHeader>
    </>
  )
};

export default Header;
